import { Component, OnInit } from '@angular/core';
import { EventService } from '../_services';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { sendMessageToHost } from '../_models';

@Component({
  selector: 'app-event-communication',
  templateUrl: './event-communication.component.html',
  styleUrls: ['./event-communication.component.scss']
})
export class EventCommunicationComponent implements OnInit {
  // Sendcommunication: Sendcommunication;
  eventComm: sendMessageToHost;
  //submitted = false; // Track if the form is submitted
  loading = false;   // Track loading state

  constructor(private sharedModalService: BsModalService, private eventService: EventService) {
    console.log(`On Init Item: ${JSON.stringify(this.eventComm)}`);
  }

  ngOnInit(): void { }

  decline(): void {
    this.sharedModalService.hide(); // Close the modal
  }

  submitCommunication(): void {
    //this.submitted = true; // Mark form as submitted

    // Perform checks to ensure valid form submission
    //if (this.model.name && this.model.email && this.model.message) {
      this.loading = true;
      this.eventService.sendMessageToHost(this.eventComm)
        .subscribe(
          (data) => {
            console.log(`Message Sent: ${data}`);
            this.loading = false;
            //this.submitted = false;
            this.sharedModalService.hide(); // Close modal on success
          },
          (error: HttpErrorResponse) => {
            console.error(`Error: ${error.message}`);
            this.loading = false;
          }
        );
    //}
  }

  
}