<div class="summary theme-new-green">
    <div id="page-header" class="banner-area-1">
        <h1 class="bh-sl-title">My Message Board</h1>
        <!-- <div class="row subheader-area-1">
            <div class="form-group main-title-info">
                <span class="icon-location-green"></span><a [href]="mapUrl"
                    target="_blank">{{summary?.location?.sales_location}}</a>
                <ng-template #copyTooltipTemplate>{{ tooltipText }}</ng-template>
                <button #btnCopy class="btn btn-outline-primary ml-3" (click)="copyToClipboard(btnCopy)"
                    [tooltip]="copyTooltipTemplate" type="button"><i class="fa fa-copy"></i></button>
            </div>
            <div class="form-group main-title-info-sm">
                <span class="icon-calander-green"></span> {{summary?.schedule?.event_start_datetime | date: 'fullDate'}}
                {{summary?.schedule?.event_start_datetime | date:'shortTime'}} - {{summary?.schedule?.event_end_datetime
                |
                date:'shortTime'}}
            </div>
        </div> -->
    </div>

    <!-- <div class="row pt-3">
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-3"><strong>When: </strong></div>
                <div class="col-sm-3">Date</div>
                <div class="col-sm-6">{{summary?.schedule?.event_start_datetime | date: 'fullDate'}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">Start Time</div>
                <div class="col-sm-6">{{summary?.schedule?.event_start_datetime | date:'shortTime'}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">End Time</div>
                <div class="col-sm-6">{{summary?.schedule?.event_end_datetime | date:'shortTime'}}</div>
            </div>
        </div>
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-2"><strong>Where: </strong></div>
                <div class="col-sm-3">Address</div>
                <div class="col-sm-7"><a [href]="mapUrl" target="_blank">{{summary?.location.start_streetno}}
                        {{summary?.location?.end_streetno == '' ? ' ' : ' - ' }}
                        {{summary?.location?.end_streetno}} {{summary?.location?.street_name}}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-3">City</div>
                <div class="col-sm-5">{{summary?.location.city}}</div>
            </div>
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-3">Province/State</div>
                <div class="col-sm-5">{{summary?.location.prov_state}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-3"><strong>Type of Sale:</strong></div>
                <div class="col-sm-8">{{summary?.metaData?.kindOfSale}}</div>
            </div>
            <strong>Type of Sale:</strong> {{summary?.metaData?.kindOfSale}}
        </div>
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-2"><strong>Notes:</strong></div>
                <div class="col-sm-10">{{summary?.schedule?.notes}}</div>
            </div>
            <strong>Notes: </strong> {{summary?.schedule?.notes}} 
        </div>
    </div> -->

    <div class="row">
        <p-table [value]="messages" [responsive]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" style="width:20%">Searcher Name</th>
                    <th scope="col" style="width:20%">Searcher Email</th>
                    <th scope="col">Message</th>
                    <th scope="col" style="width:20%">Received On</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-msg>
                <tr>
                    <td>{{ msg.searcher_name }}</td>
                    <td>{{ msg.searcher_email }}</td>
                    <td class="message-text">
                        <button type="button" class="btn-link" (click)="viewCommunication(msg)">
                            {{ msg.searcher_message }}
                        </button>
                    </td>
                    <td>{{ msg.message_received_on | date:'medium' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">No messages found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>