<div class="modal-body text-center">
  <h3>Send Your Message</h3>
  <form name="form" (ngSubmit)="f.form.valid && submitCommunication()" #f="ngForm" novalidate>

    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title pull-left">Event Communication</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="modal-body text-left">

      <!-- Name Field-->
      <label for="communication_name">Name</label>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
        <input type="text" id="communication_name" name="communication_name" placeholder="Name"
          [(ngModel)]="eventComm.seacher_name" class="form-control name-input" #name="ngModel" required
          pattern="^[a-zA-Z\s]+$" />
        <div *ngIf="f.submitted && name.invalid" class="text-danger">
          <div *ngIf="name.errors?.required">Name is required.</div>
          <div *ngIf="name.errors?.pattern">Name should only contain letters and spaces.</div>
        </div>
      </div>

      <!-- Email Field-->
      <label for="communication_email">Email</label>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
        <input type="email" id="communication_email" name="communication_email" placeholder="Email"
          [(ngModel)]="eventComm.seacher_email" class="form-control email-input" #email="ngModel" required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
        <div *ngIf="f.submitted && email.invalid" class="text-danger">
          <div *ngIf="email.errors?.required">Email is required.</div>
          <div *ngIf="email.errors?.pattern">Please enter a valid email address.</div>
        </div>
      </div>

      <!-- Message Field-->
      <label for="communication_message">Message</label>
      <label class="col-form-label float-right" for="message_char_count">Remaining: {{message.value.length}} / 1000</label>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !message.valid }">
        <textarea id="communication_message" name="communication_message" placeholder="Message"
          [(ngModel)]="eventComm.message" maxlength="1000" class="form-control message-input" #message="ngModel"
          required></textarea>
        <div *ngIf="f.submitted && message.invalid" class="text-danger">
          <div *ngIf="message.errors?.required">Message is required.</div>
        </div>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="loading">Send</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="decline()">Close</button>
    </div>
  </form>
</div>