import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as e from 'cors';
import { Country1, EFarmingNonRegisterData, State1 } from 'src/app/_models';
import { AlertService, LookupService, UserService } from 'src/app/_services';

@Component({
  selector: 'app-keep-me-posted',
  templateUrl: './keep-me-posted.component.html',
  styleUrls: ['./keep-me-posted.component.scss']
})
export class KeepMePostedComponent implements OnInit {

  model: EFarmingNonRegisterData;
  loading = false;
  countries: Country1[];
  states: State1[];
  chkAllSelection: boolean = false;

  constructor(private userService: UserService
    , private alertService: AlertService
    , private lookupService: LookupService
    , private router: Router) {
    this.countries = [];
    this.states = [];
    this.model = {
      email: '',
      contact_name: '',
      city: '',
      prov_state: '',
      country: '',
      postal_code: '',
      type_of_sale_interest: '',
      opt_email_letters: false,
      opt_notify_sale: false,
      opt_want_info: false,
      datetime_entered: new Date()
    };

    this.lookupService.getCountries1().subscribe(data => {
      this.countries = data;
    });

    this.lookupService.getStates1().subscribe(data => {
      this.states = data;
    });

  }

  selectedSales: string[] = [];
  kindOfSaleOptions: any[] = [{value: "Yard Sales", isDisable: false }, {value: "Garage Sales", isDisable: false}, {value: "Moving Sales", isDisable: false}, {value: "Thrift Sales", isDisable: false}, {value: "Store Closing Sales", isDisable: false}, {value: "Estate Sales", isDisable: false}, {value: "Liquidation Sales", isDisable: false}, {value: "Recyclables", isDisable: false}];
  opt1RegEmailLettersLabel = "I want to receive e-news, e-publications, e-letters, offers, and articles on Sale Events.";
  opt2NotifyOfSaleLabel = "Kindly notify me of ongoing sales in my area.";
  opt3WantInfoLabel = "I want to be contacted for more information on this site and similar sites.";
  getzipname(): string {
    return this.model.country == 'CA' ? "Postal Code" : "Zip code";
  }
  ngOnInit(): void {
  }

  eFarmingSubscribe() {
    this.model.type_of_sale_interest = this.selectedSales.toString();
    this.loading = true;
    this.userService.eFarmingUserSubscribe(this.model)
      .subscribe(
        data => {
          this.loading = false;
          this.alertService.success(data.message, true);
          this.router.navigate(['/home']);
        },
        error => {
          console.log(error);
          console.log(error.error.message);
          // console.log(JSON.parse(error.error));
          // this.alertService.error(JSON.parse(error._body || error).messsage);
          this.alertService.error(error.error.message);
          this.loading = false;
         
          this.router.navigate(['/home']);
        }
        
      );
  }

  onTypeOfSaleAllChange(event: any)
  {
      if(event.checked)
      {
        this.selectedSales = this.kindOfSaleOptions.map(op =>{ return op.value; });
        
        this.kindOfSaleOptions = this.kindOfSaleOptions.map(op =>{
          return {...op, isDisable: true};
        });
      }
      else{
        this.kindOfSaleOptions = this.kindOfSaleOptions.map(op =>{
          return {...op, isDisable: false};
        });
      }
  }

  decline() {
    this.router.navigate(['/home']);
  }
}
