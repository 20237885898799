import { Component, OnInit } from '@angular/core';
import { EventCommunicationReplyComponent } from '../event-communication-reply/event-communication-reply.component';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../_services';
import { EventCommunication, sendMessageToHost } from '../_models';
import { ActivatedRoute } from '@angular/router';
import { any } from 'underscore';


@Component({
  selector: 'app-event-message',
  templateUrl: './event-message.component.html',
  styleUrls: ['./event-message.component.scss']
})
export class EventMessageComponent implements OnInit {
  mapUrl: any;
  messages: EventCommunication[] = []; // Store API data
  eventIdentifier: string;

  modalRef: BsModalRef;
  // eventIdentifier: string;

  summary: any;

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(private modalService: BsModalService, private eventService: EventService, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.eventIdentifier = this.router.snapshot.params.eventIndentifier;
    this.fetchMessages();
  }


  fetchMessages(): void {
    this.eventService.getEventMessages().subscribe(
      (data) => {
        this.messages = data; // Store the API response
      },
      (error) => {
        console.error('Error fetching messages:', error);
      }
    );
  }



  viewCommunication(msg: EventCommunication): void {
    const initialState = {
      eventReply: {
        event_id: msg.event_id,
        received_on: msg.received_on,
        searcher_name: msg.searcher_name,
        searcher_email: msg.searcher_email,
        searcher_message: msg.searcher_message,
        reply: {
          message: '',
          sent_on: new Date()
        }
      }
    };

    this.modalRef = this.modalService.show(EventCommunicationReplyComponent,
      Object.assign({ initialState }, this.config, { class: 'gray modal-lg' }));
  }
}