import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { EventCommunication } from '../_models';
import { AlertService, EventService } from '../_services';

@Component({
  selector: 'app-event-communication-reply',
  templateUrl: './event-communication-reply.component.html',
  styleUrls: ['./event-communication-reply.component.scss']
})
export class EventCommunicationReplyComponent implements OnInit {

  eventReply: EventCommunication;
  loading = false;

  constructor(private sharedModalService: BsModalService, private eventService: EventService, private alertService: AlertService) {
    console.log(`On Init Item: ${JSON.stringify(this.eventReply)}`);
  }

  ngOnInit(): void { 
    
  }

  decline(): void {
    this.sharedModalService.hide(); // Close the modal
  }

  submitReply(): void {
    // Set the sent_on date before sending
    this.eventReply.reply.sent_on = new Date();
    console.log("Payload being sent to the API:", this.eventReply);
    this.loading = true;
    this.eventService.sendMessageToUser(this.eventReply)
      .subscribe(
        (data) => {
          console.log(`Message Sent: ${data}`);
          this.alertService.success(data, true);
          this.loading = false;
          //this.submitted = false;
          this.sharedModalService.hide(); // Close modal on success
        },
        (error: HttpErrorResponse) => {
          console.error(`Error: ${error.message}`);
          this.alertService.error(error.error.message);
          this.loading = false;
        }
      );
  }

}







