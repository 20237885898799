import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ngx-custom-validators';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RefreshTokenInterceptor } from './http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  NAV_DROPDOWN_DIRECTIVES, SIDEBAR_TOGGLE_DIRECTIVES,
  AsideToggleDirective, BreadcrumbsComponent, CanAccessDirective
} from './shared';

// tslint:disable-next-line:max-line-length
// import { BsDropdownModule, BsDatepickerModule, TimepickerModule, AccordionModule, ModalModule, BsModalService, RatingModule, TooltipModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
// import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';

// Common
import { LoaderService } from './loader/loader.service';
import { LoaderComponent } from './loader/loader.component';
import { AuthGuard, RoleGuard } from './_guards/index';

// Directives
import { AlertComponent } from './_directives/index';

// Services
import { AlertService, AuthenticationService, UserService, LookupService, EventService, ErrorService, AnalyticService } from './_services/index';

import {
  DropdownModule, DialogModule, InputMaskModule, CheckboxModule, TableModule
} from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';
// import { MessageService } from primeng/api;
import { AgmCoreModule } from '@agm/core';

// Pages
import { LoginComponent } from './login/index';
import { HomeComponent, NoaccessComponent } from './home/index';
import { ResetPasswordComponent } from './resetpassword/index';
import { ForgotPasswordComponent } from './forgotpassword/index';
import { UserProfileComponent } from './user/index';
import { RegisterComponent } from './register/register.component';
// import { LocationComponent } from './event/location.component';
// import { DirectionGmapDirective } from './_directives/direction-gmap.directive';
// import { ScheduleComponent } from './event/schedule.component';
// import { ItemComponent } from './event/item.component';
import { EventItemComponent } from './yaga-event/event/event-item.component';
import { ItemPictureComponent } from './yaga-event/event/item-picture.component';
// import { AngularFileUploaderModule } from 'angular-file-uploader';
// import { NgSelectModule } from '@ng-select/ng-select';
import { SanitizeHtmlPipe } from './_pipe/sanitize-html.pipe';
import { DynamicPatternValidatorDirective } from './_common/customValidator/dynamic.pattern.directive';
import { MenuComponent } from './shared/menu/menu.component';
import { HeaderComponent } from './shared/header/header.component';
import { EventSearchComponent } from './event-search/event-search.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CopyrightOwnershipComponent } from './pages/copyright-ownership/copyright-ownership.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { UnderconstructionComponent } from './pages/underconstruction/underconstruction.component';
import { ErrorpageComponent } from './pages/errorpage/errorpage.component';
import { BenefitsComponent } from './pages/benefitsuse/benefits/benefits.component';
import { HowitworksComponent } from './pages/benefitsuse/howitworks/howitworks.component';
import { HowtohostComponent } from './pages/benefitsuse/howtohost/howtohost.component';
import { HowtosearchComponent } from './pages/benefitsuse/howtosearch/howtosearch.component';
import { SysreqComponent } from './pages/help/sysreq/sysreq.component';
import { FaqComponent } from './pages/help/faq/faq.component';
import { EventHeaderComponent } from './shared/event-header/event-header.component';
import { EventWizardStepComponent } from './shared/event-wizard-step/event-wizard-step.component';
import { SharedModule } from './shared/shared.module';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { BenefitsuseComponent } from './pages/benefitsuse/benefitsuse.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { GridViewComponent } from './event-views/grid-view/grid-view.component';
import { ListViewComponent } from './event-views/list-view/list-view.component';
import { ErrorComponent } from './shared/error/error.component';
import { environment } from 'src/environments/environment';
import { OrganizeComponent } from './pages/organize/organize.component';
import { ReviewFreewillPayComponent } from './pages/review-freewill-pay/review-freewill-pay.component';
import { HelpAssistSetUpComponent } from './pages/help-assist-set-up/help-assist-set-up.component';
import { KeepMePostedComponent } from './pages/keep-me-posted/keep-me-posted.component';
import { SubscriptionTierComponent } from './pages/subscription-tier/subscription-tier.component';
import { EventCommunicationComponent } from './event-communication/event-communication.component';
import { EventCommunicationReplyComponent } from './event-communication-reply/event-communication-reply.component';
import { EventMessageComponent } from './event-message/event-message.component';

//  import { EventComponent } from './event/event.component';

// import { NgWizardModule } from '@cmdap/ng-wizard';
// import { Step1Component } from './test/step1/step1.component';
// import { Step2Component } from './test/step2/step2.component';
// import { Step3Component } from './test/step3/step3.component';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    BreadcrumbsComponent,
    CanAccessDirective,
    AlertComponent,
    LoaderComponent,

    LoginComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NoaccessComponent,
    UserProfileComponent,
    RegisterComponent,
    SanitizeHtmlPipe,
    DynamicPatternValidatorDirective,
    MenuComponent,
    HeaderComponent,
    EventSearchComponent,
    FooterComponent,
    AboutusComponent,
    PrivacyComponent,
    CopyrightOwnershipComponent,
    ContactUsComponent,
    FeedbackComponent,
    UnderconstructionComponent,
    ErrorpageComponent,
    BenefitsComponent,
    HowitworksComponent,
    HowtohostComponent,
    HowtosearchComponent,
    SysreqComponent,
    FaqComponent,
    EventHeaderComponent,
    EventWizardStepComponent,
    BenefitsuseComponent,
    EventDetailComponent,
    GridViewComponent,
    ListViewComponent,
    ErrorComponent,
    OrganizeComponent,
    ReviewFreewillPayComponent,
    HelpAssistSetUpComponent,
    KeepMePostedComponent,
    SubscriptionTierComponent,
    EventCommunicationComponent,
    EventCommunicationReplyComponent,
    EventMessageComponent,
    // Step1Component, Step2Component, Step3Component
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastModule,
    DropdownModule,
    DialogModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),
    // ModalModule.forRoot(),
    RatingModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    InputMaskModule,
    CheckboxModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmap_api_key,
      libraries: ['places']
    }),
    SharedModule,
    TableModule
    // NgWizardModule
  ],
  exports: [
    LoaderComponent,
    EventHeaderComponent,
    EventWizardStepComponent,
    ErrorComponent
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    AlertService,
    AuthenticationService,
    UserService,
    LookupService,
    EventService,
    ErrorService,
    AnalyticService,
    // MessageService,
    // BsModalService,
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true

      /* provide: Http,
       useFactory: httpFactory,
       deps: [XHRBackend, RequestOptions, LoaderService]*/
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [EventItemComponent, ItemPictureComponent, ErrorComponent, EventCommunicationComponent, EventCommunicationReplyComponent]

})
export class AppModule { }
