<div class="modal-body text-center">
  <h3>Send Your Message</h3>
  <form name="form" (ngSubmit)="f.form.valid && submitReply()" #f="ngForm" novalidate>
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title pull-left">Message Reply</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="modal-body text-left">


      <!-- Name Field -->
      <div class="form-group">
        <label class="col-form-label" for="reply_name">Name</label>
        <input type="text" id="reply_name" name="reply_name" class="form-control name-input" [value]="eventReply.searcher_name"
          disabled />
      </div>

      <!-- Email Field -->
      <div class="form-group">
        <label class="col-form-label" for="reply_email">From</label>
        <input type="text" id="reply_email" name="reply_email" class="form-control email-input" [value]="eventReply.searcher_email"
          disabled />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="message">Original Message</label>
        <textarea type="text" id="message" name="message" class="form-control" [value]="eventReply.searcher_message"
          disabled></textarea>
      </div>

      <!-- Message Field -->
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !reply.valid }">
        <label class="col-form-label" for="reply_message">Reply Message</label>
        <label class="col-form-label float-right" for="message_char_count">Remaining: {{reply.value.length}} / 1000</label>
        <textarea id="reply_message" name="reply_message" placeholder="Reply" [(ngModel)]="eventReply.reply.message"
          maxlength="1000"  class="form-control message-input" #reply="ngModel" required></textarea>
        <div *ngIf="f.submitted && reply.invalid" class="text-danger">
          <div *ngIf="reply.errors?.required">Message is required.</div>
        </div>
      </div>

    </div>
    <!-- Modal Footer -->
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Send</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="decline()">Close</button>
    </div>
  </form>
</div>