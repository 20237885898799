import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions, Response } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { EventLocation, EventSchedule, ScheduleDTO, EventItems, BroadcastEventDTO, EventSummary, Survey, SurveyStat, QuickEventDetail,sendMessageToHost,sendMessageToUser, EventCommunication} from '../_models/index';
import { map } from 'rxjs/operators';

@Injectable()
export class EventService {

   

    constructor(private http: HttpClient) { }

    saveEventLocation(eventLocation: EventLocation) {
        return this.http
            .post<any>('/event/saveLocation', eventLocation).toPromise();
        // .map((response: Response) => response.json());
    }

    saveEventSchedule(eventSchedule: EventSchedule) {
        return this.http
            .post<any>('/event/saveSchedule', eventSchedule).toPromise();
        // .map((response: Response) => response.json());
    }

    saveEventItems(eventItems: EventItems) {
        return this.http
            .post<any>('/event/saveItems', eventItems).toPromise();
    }

    submitEventSurvey(survey: Survey) {
        return this.http
            .post<any>('/event/submitSurvey', survey).toPromise();
    }

    getSurveyStat(eventIdentifier: string): Observable<SurveyStat> {
        eventIdentifier = encodeURIComponent(eventIdentifier);
        console.log(`Get Items api: /event/${eventIdentifier}/surveyStat`);

        return this.http.get<SurveyStat>(`/event/${eventIdentifier}/surveyStat`);
    }

    broadcastEvent(eventIdentifier: string)
    {
        return this.http
        .post<any>(`/event/broadcastEvent`, JSON.stringify(eventIdentifier)).toPromise();
    }

    getEventLocations(userName: string): Observable<EventLocation[]> {
        return this.http.get<EventLocation[]>(`/event/locations/${userName}`);
    }

    getEventSchedules(userName: string): Observable<ScheduleDTO[]> {
        return this.http.get<ScheduleDTO[]>(`/event/schedules/${userName}`);
    }

    getLocationSchedules(user: string, locationIdentifier: string): Observable<ScheduleDTO[]> {
        return this.http.get<ScheduleDTO[]>(`/event/${user}/${locationIdentifier}/schedules`);
    }

    getScheduleItems(scheduleIdentifier: string, itemKind?: string): Observable<EventItems[]> {
        scheduleIdentifier = encodeURIComponent(scheduleIdentifier);
        console.log(`Get Items api: /event/${scheduleIdentifier}/${itemKind}/items`);

        return this.http.get<EventItems[]>(`/event/${scheduleIdentifier}/${itemKind}/items`);
    }

    getEventSummary(scheduleIdentifier: string): Observable<EventSummary> {
        scheduleIdentifier = encodeURIComponent(scheduleIdentifier);
        console.log(`Get Items api: /event/${scheduleIdentifier}/summary`);

        return this.http.get<EventSummary>(`/event/${scheduleIdentifier}/summary`);
    }

    getEvent(eventIdentifier: string): Observable<EventSummary> {
        console.debug(`Get Event api: /event/getEvent/${eventIdentifier}`);
        return this.http.get<EventSummary>(`/event/getEvent/${eventIdentifier}`);
    }

    queryEventByRadius(lat: number, lng: number, radius: number): Observable<BroadcastEventDTO[]> {
        // tslint:disable-next-line:max-line-length
        return this.http.get<BroadcastEventDTO[]>(`/event/queryRadius?lat=${lat}&lng=${lng}&radiusInMeters=${radius}`);
        //return this.http.get<BroadcastEventDTO[]>('assets/data/search-result.json');
    }

    getScheduleItemsCount(scheduleIdentifier: string): Observable<number> {
        scheduleIdentifier = encodeURIComponent(scheduleIdentifier);
        console.log(`Get Items count api: /event/${scheduleIdentifier}/itemcount`);

        return this.http.get<number>(`/event/${scheduleIdentifier}/itemcount`);
    }

    saveQuickEventDetails(quickEventDetail: QuickEventDetail) {
        // tslint:disable-next-line:max-line-length
        return this.http
          .post<any>('/event/saveQuickEventDetails', quickEventDetail);
    }

    updateViewCount(eventId: string) {
        return this.http
          .get<number>(`/event/${eventId}/pageViews`);
    }
    sendMessageToHost(eventCommunication: sendMessageToHost) {
        // tslint:disable-next-line:max-line-length
       return this.http.post<any>('/event/sendMessageToHost', eventCommunication);

    }

    sendMessageToUser(eventCommunication: EventCommunication) {
        // tslint:disable-next-line:max-line-length
       return this.http.post<any>('/event/sendMessageToUser', eventCommunication);

    }

    //Show list    
    getEventMessages(): Observable<EventCommunication[]> {
        return this.http.get<EventCommunication[]>('/event/messages');
        // .pipe(
        //     map((data: EventCommunication[]) => 
        //         data.map(msg => ({
        //             event_id: msg.event_id,
        //             seacher_name: msg.searcher_name,
        //             seacher_email: msg.searcher_email,
        //             message: msg.searcher_message // Mapping `searcher_message` to `message`
        //         }))
        //     )
        // );
    }


    
}
